<template>
  <v-data-table :headers="headers" :items="items" dense @click:row="onClickRow" :search="search">
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-col cols="12" sm="12" md="9" lg="9">
            <v-text-field
              flat
              dense
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ items.findIndex(x => x === item) + 1 }}
    </template>
    <template v-slot:item.totalAmount="{ item }">
      {{ formatPrice(item.totalAmount) }}
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "journal-return-mr",
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Return MR ID",
        value: "returnMrId",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Return MR#",
        value: "returnMrNumber",
        sortable: false,
        align: "left",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Job ID",
        value: "jobId",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Total",
        value: "totalAmount",
        sortable: false,
        align: "right",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    search: "",
    items: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("inventory/getJournalReturnMr")
        .then(resposne => {
          this.items = resposne.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onClickRow(item) {
      this.$router.push({
        name: "Detail Journal Return MR",
        params: { id: item.id },
        query: { type: "rmr", relationId: item.returnMrId },
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
